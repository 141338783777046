<template>
  <div>
    <table class="table table-sm border-top-0">
      <tbody>
      <tr>
        <td v-text="$t('file.name')"></td>
        <td v-text="">
          <router-link
              v-if="file"
              :to="{name:'uploaded-file', params: { token: file.id} }"
              active-class="active"
              v-bind:title="file.name"
              v-text="file.name">
          </router-link>
        </td>
      </tr>
      <tr>
        <td v-text="$t('project.project')"></td>
        <td>
          <project-code v-bind:id="file.projects" :show-name="true"></project-code>
        </td>
      </tr>
      <tr>
        <td v-text="$t('file.state')"></td>
        <td>
          <file-state v-bind:state="file.state"></file-state>
        </td>
      </tr>
      <tr>
        <td v-text="$t('file.size')"></td>
        <td>{{ file.size | humanFileSize }}</td>
      </tr>
      <tr>
        <td v-text="$t('file.lines')"></td>
        <td v-text="file.lines"></td>
      </tr>
      <tr>
        <td v-text="$t('file.uploadedAt')"></td>
        <td>{{ file.createdAt | stringDateToLocalDateString }}</td>
      </tr>
      <tr>
        <td v-text="$t('file.uploadedBy')"></td>
        <td>
          <user-name v-bind:id="file.createdBy"></user-name>
        </td>
      </tr>
      <tr>
        <td v-text="$t('file.lastModifiedAt')"></td>
        <td>{{ file.lastModifiedAt | stringDateToLocalDateString }}</td>
      </tr>
      <tr>
        <td v-text="$t('file.lastModifiedBy')"></td>
        <td>
          <user-name v-bind:id="file.lastModifiedBy"></user-name>
        </td>
      </tr>
      <tr>
        <td>Id</td>
        <td>{{file.id}}</td>
      </tr>
      </tbody>
    </table>
    <div class="row">
      <div class="col col-6  align-content-center">
        <button
            type="button"
            class="btn btn-primary btn-block"
            v-on:click="previewToggle">
          {{ $t('file.preview') }}
          <font-awesome-icon icon="eye"></font-awesome-icon>
        </button>
      </div>
      <div class="col col-6">
        <a v-bind:href='"/api/uploads/" + file.id + "/download"'>
          <button
              type="button"
              class="btn btn-primary btn-block"
              v-on:click=""
          >
            {{ $t('file.download') }}
            <font-awesome-icon
                class="click"
                icon="download"
                v-bind:title="$t('file.download')"
            ></font-awesome-icon>
          </button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadedFileInfo",
  props: {
    file: {
      type: Object,
      default: null
    },
  },
  methods: {
    previewToggle() {
      this.$emit('toggle-preview');
    }
  },
}
</script>

<style scoped>

</style>
