<template>
  <div class="d-flex xs">
    <label class="align-self-center pt-2"></label>
    <input type="number" class="form-control text-center my-1 mr-1"
           :value="pageSize"
           @change="setPageSize"/>
    <nav>
      <ul class="pagination pagination-sm mb-1 mt-1">
        <li class="page-item" v-for="item in items"
            :class="{disabled: item.isDisabled, active: item.active}">
          <router-link class="page-link"
                       :to="{query: to(item.page), hash}">
            {{item.label}}
          </router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'

  export default {
    props: {
      page: {},
      pageSize: {
        type: [Number,String],
      },
      totalItems: {
        type: [Number,String],
      },
      namespace: {
        type: String,
      },
      hash: String,
    },
    methods: {
      pageItem(page) {
        return {
          label: page,
          page: page,
          disabled: false,
          active: this.page === page
        }
      },
      setPageSize: debounce(function (e) {
        if (this.namespace) {
          this.$router.push({
            query: Object.assign({}, this.$route.query, { [this.namespace + '.pageSize']: parseInt(e.target.value, 10)})
          })
        } else {
          this.$router.push({
            query: Object.assign({}, this.$route.query, {pageSize: parseInt(e.target.value, 10)})
          })
        }
      }, 200),
      to(page) {
        if (this.namespace) {
          return Object.assign({}, this.$route.query, {[this.namespace + '.page']: page})
        }
        return Object.assign({}, this.$route.query, {page})
      }
    },
    computed: {
      pages() {
        const vm = this;
        return Math.ceil(vm.totalItems / vm.pageSize)
      },
      first() {
        const vm = this;
        return {label: "«", page: 1, isDisabled: vm.page === 1, active: false};
      },
      last() {
        const vm = this;
        const pages = vm.pages;
        return {label: '»', page: pages, isDisabled: vm.page === pages, active: false}
      },
      prev() {
        const vm = this;
        return {label: "‹", page: vm.page - 1, isDisabled: vm.page === 1, active: false};
      },
      next() {
        const vm = this;
        const pages = vm.pages;
        return {label: "›", page: vm.page + 1, isDisabled: vm.page === pages, active: false};
      },
      items() {
        const vm = this;
        const arr = [];
        const pages = Math.ceil(vm.totalItems / vm.pageSize);
        if (!(vm.page && vm.pageSize && vm.totalItems)) {
          return [];
        }
        if (vm.pageSize > vm.totalItems) {
          return [];
        }
        arr.push(vm.first);
        arr.push(vm.prev);
        if (pages < 9) {
          for (let idx = 0; idx < vm.pages; idx += 1) {
            arr.push(vm.pageItem(idx + 1));
          }
        }

        if (pages >= 9) {
          if (vm.page < 5) {
            for (let idx = 0; idx < 6; idx += 1) {
              arr.push(vm.pageItem(idx + 1))
            }
            arr.push({
              label: "...",
              page: "#",
              isDisabled: true,
            })
          } else if (pages - vm.page < 5) {
            arr.push({
              label: "...",
              page: "#",
              isDisabled: true,
            });
            for (let idx = vm.pages - 6; idx < vm.pages; idx += 1) {
              arr.push(vm.pageItem(idx + 1))
            }
          } else {
            arr.push({
              label: "...",
              page: "#",
              isDisabled: true,
            });
            for (let idx = vm.page - 3; idx < vm.page + 2; idx += 1) {
              arr.push(vm.pageItem(idx + 1))
            }
            arr.push({
              label: "...",
              page: "#",
              isDisabled: true,
            });
          }
        }
        arr.push(vm.next);
        arr.push(vm.last);

        return arr;
      }
    }
  }
</script>

<style scoped>
  li.page-item a {
    width: 34px;
    min-width: 34px;
    text-align: center;
  }

  .xs {
    font-size: 12px !important;
    font-family: 'Verdana', monospace;
  }

  .xs nav a {
    padding: 2px 0 !important;
    /*width: 25px !important;*/
    min-width: 28px;
  }

  .xs .form-control {
    font-size: 12px !important;
    font-family: 'Verdana', monospace;
    padding: 0 6px !important;
    padding-bottom: 1px;
    height: 26px;
    width: 50px;
  }
</style>