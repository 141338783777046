const humanFileSize = (bytes, si) => {
  // https://stackoverflow.com/a/14919494/1762113
  const thresh = si ? 1000 : 1024;
  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }
  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  do {
    bytes /= thresh;
    ++u;
  } while (Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1) + ' ' + units[u];
};

const stringDateToLocalDateString = (dateString) => {
  const d = new Date(dateString);
  return d.toLocaleDateString();
};

const stringDateToLocalTimeString = (dateString) => {
  const d = new Date(dateString);
  return d.toLocaleTimeString();
};

const stringDateToLocalDateTimeString = (dateString) => {
  const d = new Date(dateString);
  return d.toLocaleDateString() + ' - ' + d.toLocaleTimeString();
};

const userToName = (user) => {
  let result = null;
  if (!user) {
    return 'no user';
  }

  if (user.lastName) {
    result = user.lastName;
  }

  if (user.firstName) {
    if (result){
      result += ', ' + user.firstName;
    } else {
      result = user.firstName;
    }
  }

  return result;
};

export default {
  install(Vue) {
    Vue.filter('humanFileSize', humanFileSize);
    Vue.filter('stringDateToLocalDateString', stringDateToLocalDateString);
    Vue.filter('stringDateToLocalTimeString', stringDateToLocalTimeString);
    Vue.filter('stringDateToLocalDateTimeString', stringDateToLocalDateTimeString);
    Vue.filter('userToName', userToName);
  }
};