export default {
  methods: {
    namespaceQueryFilter(query) {
      if (this.isNamespaceActive) {
        const prefix = this.currentNamespace + '.';
        const filteredQuery = {};
        Object.entries(query).forEach(([key, value]) => {
          if (key.startsWith(prefix)) {
            const trimmedKey = key.replace(prefix, '');
            filteredQuery[trimmedKey] = value;
          }
        });
        return filteredQuery;
      } else {
        return query;
      }
    }
  },
  computed: {
    namespacedPagination() {
      return {
        ...this.pagination,
        namespace: this.currentNamespace,
      };
    },
    isNamespaceActive() {
      return this.namespace || !!this.namespaceName;
    },
    currentNamespace() {
      if (this.isNamespaceActive) {
        return this.namespaceName || this.defaultNamespace;
      } else {
        return null;
      }
    },
  },
};