import Vue from 'vue';

import VueRouter from 'vue-router';

import store from '../store';
import i18n from '../i18n/index';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/users',
      name: 'users',
      component: () => import('../modules/user/UserManagementPage.vue'),
      meta: {
        featureName: 'users',
        requiresAuth: true,
        permissions: ['users:manage'],
      },
      children: [
        {
          path: 'list',
          name: 'user-list',
          components: {
            list: () => import('../modules/user/UserList.vue'),
          },
          meta: {
            featureName: 'users',
            requiresAuth: true,
            permissions: ['users:manage'],
          },
        },
      ],
    },
    {
      path: '/user/:userId',
      name: 'user',
      props: true,
      component: () => import('../modules/user/UserManagementPage.vue'),
      meta: {
        featureName: 'users',
        requiresAuth: true,
        permissions: ['users:manage'],
      },
      children: [
        {
          path: 'files',
          name: 'user-files',
          props: true,
          components: {
            nav: () => import('../modules/user/UserManageNav'),
            list: () => import('../modules/user/FileList'),
          },
          meta: {
            featureName: 'users',
            requiresAuth: true,
            permissions: ['users:manage'],
          },
        },
        {
          path: 'events',
          name: 'user-events',
          props: true,
          components: {
            nav: () => import('../modules/user/UserManageNav'),
            list: () => import('../components/EventList'),
          },
          meta: {
            featureName: 'users',
            requiresAuth: true,
            permissions: ['users:manage'],
          },
        },
        {
          path: 'edit',
          name: 'user-edit',
          props: true,
          components: {
            nav: () => import('../modules/user/UserManageNav'),
            list: () => import('../modules/user/UserList.vue'),
          },
          meta: {
            featureName: 'users',
            requiresAuth: true,
            permissions: ['users:manage'],
          },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('../modules/authentication/Login.vue'),
      meta: {
        featureName: 'login',
        requiresAuth: false,
      },
    },
    {
      path: '/login/id-card',
      name: 'id-card-login',
      component: () => import('../modules/authentication/IdCardLoginCall.vue'),
      meta: {
        featureName: 'login',
        requiresAuth: false,
      },
    },
    {
      path: '/uploads',
      name: 'uploads-list',
      component: () => import('../modules/uploads/list/UploadedFileList.vue'),
      meta: {
        featureName: 'uploads-list',
        requiresAuth: true,
        permissions: ['file:upload','file:convert'],
      },
    },
    {
      path: '/uploads-read',
      name: 'uploads-read',
      component: () => import('../modules/uploads/pre-uploaded-read/PreUploadedFileRead.vue'),
      meta: {
        featureName: 'uploads-list',
        requiresAuth: true,
        permissions: ['file:upload','file:convert'],
      },
    },
    {
      path: '/uploads/:token',
      name: 'uploaded-file',
      props: true,
      component: () => import('../modules/uploads/upload/UploadedFile.vue'),
      meta: {
        featureName: 'uploads-list',
        requiresAuth: true,
        permissions: ['file:view'],
      },
    },
    {
      path: '/uploads/:token/convert',
      name: 'upload-start-conversion',
      component: () => import('../modules/converter/Converter.vue'),
      props: true,
      meta: {
        featureName: 'code-conversion',
        requiresAuth: true,
        permissions: ['file:convert'],
      },
    },
    {
      path: '/conversions/:conversionToken',
      name: 'convert-result',
      props: true,
      component: () => import('../modules/conversions/conversion/ConversionResult.vue'),
      meta: {
        featureName: 'uploads-list',
        requiresAuth: true,
        permissions: ['file:convert'],
      },
    },
    {
      path: '/conversions/',
      name: 'conversions-list',
      props: true,
      component: () => import('../modules/conversions/list/ConversionsList'),
      meta: {
        featureName: 'conversions-list',
        requiresAuth: true,
        permissions: ['file:convert'],
      },
    },
    {
      path: '/projects/',
      name: 'projects-list',
      props: true,
      component: () => import('../modules/projects/list/ProjectsList'),
      meta: {
        featureName: 'projects-list',
        requiresAuth: true,
        permissions: ['project:view'],
      },
    },
    {
      path: '/projects/:id',
      name: 'project',
      props: true,
      component: () => import('../modules/projects/project/Project'),
      meta: {
        featureName: 'projects-list',
        requiresAuth: true,
        permissions: ['project:view'],
      },
    },
    {
      path: '/code-usage/',
      name: 'code-usage',
      props: true,
      component: () => import('../modules/codeUsage/codeUsage'),
      meta: {
        featureName: 'code-usage',
        requiresAuth: true,
        permissions: ['code-usage:view'],
      },
    },
    {
      path: '/code-usage/:ucode',
      name: 'code-usage-view',
      props: true,
      component: () => import('../modules/codeUsage/codeUsageView'),
      meta: {
        featureName: 'code-usage',
        requiresAuth: true,
        permissions: ['code-usage:view'],
      },
    },
    {
      path: '/',
      name: 'home',
      component: () => import('../modules/profile/HomePage.vue'),
      meta: {
        featureName: 'home',
        requiresAuth: false,
      },
    },
  ]
});

// const alwaysAllowed = [(to) => to.path.startsWith('/login'), (to) => to.path.startsWith('/logout')];

router.beforeEach(async (to, from, next) => {
  console.log('beforeEach', to, from);
  await store.commit('clearGlobalFlash'); // IH: maybe not needed any more
  // using local storage to set preferred language
  if (localStorage.language && localStorage.language !== i18n.locale) {
    i18n.locale = localStorage.language;
  }
  // IH: maybe add else and use navigator.languages to pick preferred

  const currentUser = await store.dispatch('profile/currentUser');

  if (currentUser && to.path.startsWith('/login')) {
    console.log('redirect from login to previous');
    next({path: to.query.previousUrl || '/'});
  } else if (currentUser) {
    // check if the feature is enabled
    const activeFeature = to.matched.reduce((active, r) => {
      const a = store.state.features.pages.includes(r.meta.featureName);
      return active || a;
    }, false);
    if (!activeFeature) {
      // not enabled feature
      console.log('redirect to home');
      next({
        path: '/'
      });
    } else {
      // requiresAuth -> do the can check, else let there
      if (!to.matched.some(record => record.meta.requiresAuth)) {
        console.log('continue to route');
        next();
      } else {
        // go trough all the matched routes and check if allowed
        const authorised = to.matched.reduce((allowed, r) => {
          const a = router.app.$can(...r.meta.permissions);
          return allowed || a;
        }, false);

        if (authorised) {
          console.log('continue to route');
          next();
        } else {
          // user in location they should not be with their current permissions
          console.log('redirect to home');
          next({
            path: '/'
          });
        }
      }
    }
  } else if (to.path.startsWith('/login')) {
    console.log('allow to login');
    next();
  } else {
    next({
      path: '/login', query: {
        previousUrl: to.fullPath,
      }
    });
  }
});

export default router;
