import http from './index';

const requests = function (axiosInstance) {
  return {
    getProjectListAsync: async (query = {}) => {
      return axiosInstance.get('/projects', {params: query});
    },
    createProjectAsync: async (formData) => {
      return axiosInstance.post('/projects', formData);
    },
    getProjectAsync: async (id) => {
      return axiosInstance.get('/projects/' + id);
    },
    getProjectUploadsList: async (id, query = {}) => {
      return axiosInstance.get('projects/' + id + '/files', {params: query});
    },
    getProjectConversionsList: async (id, query = {}) => {
      return axiosInstance.get('projects/' + id + '/conversions', {params: query});
    },
    uploadAsync: async (id, files) => {
      return axiosInstance.post('projects/' + id + '/files', files);
    },
    getProjectCodesListAsync: async () => {
      return axiosInstance.get('projects/codes');
    },
    getProjectEvents: async (id, query = {}) => {
      return axiosInstance.get('projects/' + id + '/events', {params: query});
    },
  };
};

export default {
  ...requests(http)
};

module.exports.projectsRequests = requests;