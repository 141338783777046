<template>
  <transition name="modal">
    <div class="modal-mask d-flex justify-content-center">
      <slot name="override">
        <div class="modal-wrapper d-flex" :style="contentStyle">
          <div class="modal-container align-self-center" :class="classes">
            <slot name="header-override">
              <div class="modal-header">
                <slot name="header">
                </slot>
              </div>
            </slot>

            <div class="modal-body">
              <slot name="body">
              </slot>
              <slot>
              </slot>
            </div>

            <slot name="footer-override">
              <div class="modal-footer">
                <slot name="footer">
                  <button class="modal-default-button btn btn-lg btn-primary" v-on:click="close">
                    Close
                  </button>
                </slot>
              </div>
            </slot>
          </div>
        </div>
      </slot>
    </div>
  </transition>
</template>

<script>
  // import {directive as onClickaway} from 'vue-clickaway';

  export default {
    name: 'modal',
    props: {
      classes: {
        type: String,
        default: 'col-md-12',
      },
      maxWidth: {},
      contentStyle: {},
      preventClose: {
        type: Boolean,
        defaults: false,
      }
    },
    mounted() {
      window.addEventListener('keydown', this.escKeyListener);
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.escKeyListener);
    },
    methods: {
      escKeyListener(event) {
        if (event.key === "Escape" && !this.preventClose) {
          this.close();
        }
      },
      close: function () {
        this.$emit('close')
      },
    }
  }
</script>

<style lang="scss">
  $background-color: rgba(0, 0, 0, .5);
  $shadow-color: rgba(0, 0, 0, .33);

  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $background-color;
    display: table;
    transition: opacity .3s ease;
  }

  .modal-container {
    padding: 20px 30px;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px $shadow-color;
    transition: all .3s ease;
    font-family: Helvetica, Arial, sans-serif;
  }

  .modal-body {
    margin: 20px 0;
    max-height: calc(100vh - 350px);
    overflow: scroll;
  }

  .modal-default-button {
    float: right;
  }

  /*
   * The following styles are auto-applied to elements with
   * transition="modal" when their visibility is toggled
   * by Vue.js.
   *
   * You can easily play with the modal transition by editing
   * these styles.
   */

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
