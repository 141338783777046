<template>
  <transition name="flash">
    <div class="flash" v-if="messages != null && !forceHidden">
      <div class="alert alert-danger" v-show="hasErrors">
        <button type="button" class="close" @click="forceHidden = true" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        <slot></slot>
        <div v-for="i in generalErrors">
          <span v-if="i.errorType"><h5>{{ i.errorType }}</h5></span> {{ i.errorMessage }}
        </div>
        <p v-for="i in fieldErrors">
          <span><b>{{ i.fieldName }}</b>: {{ i.fieldMessage }}</span>
        </p>
      </div>
      <div class="alert alert-success" v-show="hasSuccess">
        {{ successMessage }}
        <button type="button" class="close" @click="forceHidden = true" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'flash-messages',
  props: {
    messages: {},
    scopePrefix: {
      type: String,
      default: null,
    },
    fieldKeyToHuman: {
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      forceHidden: false,
      sendClear: null,
    }
  },
  watch: {
    messages() {
      this.forceHidden = false;
      if (this.sendClear) {
        clearTimeout(this.sendClear);
      }
      this.sendClear = setTimeout(this.clearMessages, 5000);
    }
  },
  computed: {
    hasSuccess() {
      return !this.hasErrors && !!this.messages.success;
    },
    hasErrors() {
      return this.messages && (
          (this.messages.generalErrors && this.messages.generalErrors.length > 0) ||
          (this.messages.fieldErrors && this.messages.fieldErrors.length > 0)
      );
    },
    successMessage() {
      const vm = this;
      return vm.hasTranslation(vm.scopePrefix + '.' + vm.messages.success) || vm.hasTranslation('successMessage.' + vm.messages.success) || vm.messages.success;
    },
    generalErrors() {
      const vm = this;
      const errors = vm.messages.generalErrors || [];
      return errors.map(e => {
        const errorType = vm.hasTranslation('error.' + e.errorCode);
        const errorMessage = vm.hasTranslation(`fieldMessage.${e.message}`) || e.message || e;
        return {
          errorType,
          errorMessage,
        };
      })
    },
    fieldErrors() {
      const vm = this;
      const errors = vm.messages.fieldErrors || [];
      return errors.map(e => {
        let fieldName = vm.fieldKeyToHuman[e.param] ||
            vm.hasTranslation(vm.scopePrefix + '.form-' + e.param) ||
            vm.hasTranslation(vm.scopePrefix + '.' + e.param) ||
            vm.hasTranslation(e.param) ||
            e.param;
        const helper = e.msg.split(';');
        let fieldMessage = vm.hasTranslation('fieldMessage.' + e.msg) ||
            vm.hasTranslationWithOptions('fieldMessage.',helper) ||
            e.msg
        return {
          fieldName,
          fieldMessage,
        }
      })
    },

  },
  methods: {
    close() {
      this.forceHidden = true;
      this.$emit('clear')
    },
    clearMessages() {
      if (this.hasSuccess) {
        this.close();
      }
    },
    hasTranslation(key) {
      const vm = this;
      if (vm.$t && vm.$te && vm.$te(key)) return vm.$t(key);
      return null
    },
    hasTranslationWithOptions(prefix, options){
      const vm = this;
      const key = prefix + options.shift();
      if (vm.$t && vm.$te && vm.$te(key, options)) return vm.$t(key,options);
      return null
    },
  }
}
</script>

<style scoped>
.alert-danger p {
  margin-bottom: 0;
}

.flash-leave-active {
  -webkit-animation-name: fadeOutDown;
  animation: fadeOutDown .5s;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
}
</style>