export const state = () => ({
  id: -1,
  idCode: '',
  firstName: '',
  lastName: '',
  email: '',
  permissions: [],
  apiKey: false,
  shortName: '',
});

export const mutations = {
  setProfile(s, profile) {
    Object.keys(s).forEach(key => {
      s[key] = profile[key];
    });
  }
};

export const actions = {
  async currentUser({state: s, commit}) {
    if (s.id !== -1) {
      return s;
    }

    let user = null;

    try {
      const res = await this._vm.api.auth.getCurrentUserAsync();

      if (res.ok) {
        commit('setProfile', res.content);
        user = s;
      }

      // commit('setProfile', state());
      // commit('setGlobalFlash', res.flash, {root: true});
    } catch (e) {
      commit('setProfile', state());
      commit('setGlobalFlash', {generalErrors: [{type: '', msg: e.message}]}, {root: true});
    }

    return user;
  },
  async reloadCurrentUser({state: s, commit}) {
    let user = null;
    try {
      const res = await this._vm.api.auth.getCurrentUserAsync();

      if (res.ok) {
        commit('setProfile', res.content);
        user = s;
      } else {
        commit('setProfile', state());
        commit('setGlobalFlash', res.flash, {root: true});
      }
    } catch (e) {
      commit('setProfile', state());
      commit('setGlobalFlash', {generalErrors: [{type: '', msg: e.message}]}, {root: true});
    }

    return user;
  },

  async startMobileIdLoginAsync({commit}, {phoneNo, idCode}) {
    const res = await this._vm.api.auth.startMobileIdLoginAsync(idCode, phoneNo);
    console.log(res);
    if (res.ok) {
      return res;
    } else {
      commit('setProfile', state());
      commit('setGlobalFlash', res.flash, {root: true});
      return null;
    }
  },
  async checkMobileIdLoginAsync({state: s, dispatch, commit}, sessionCode) {
    const res = await this._vm.api.auth.checkMobileIdLoginAsync(sessionCode);
    if (res.ok) {
      if (res.content.running) {
        return await dispatch('checkMobileIdLoginAsync', sessionCode);
      } else {
        commit('setProfile', res.content);
      }
      return s;
    } else {
      commit('setProfile', state());
      commit('setGlobalFlash', res.flash, {root: true});
      return null;
    }
  },
  async startSmartIdLoginAsync({commit}, {idCode}) {
    const res = await this._vm.api.auth.startSmartIdLoginAsync(idCode);
    if (res.ok) {
      return res;
    } else {
      commit('setProfile', state());
      commit('setGlobalFlash', res.flash, {root: true});
      return null;
    }
  },
  async checkSmartIdLoginAsync({state: s, dispatch, commit}, sessionCode) {
    const res = await this._vm.api.auth.checkSmartIdLoginAsync(sessionCode);
    if (res.ok) {
      if (res.content.running) {
        return await dispatch('checkSmartIdLoginAsync', sessionCode);
      } else {
        commit('setProfile', res.content);
      }
      return s;
    } else {
      commit('setProfile', state());
      commit('setGlobalFlash', res.flash, {root: true});
      return null;
    }
  },
  async idCardLoginAsync({state: s, commit}) {
    const res = await this._vm.api.auth.idCardLoginAsync();
    if (res.ok) {
      commit('setProfile', res.content);
      return s;
    } else {
      commit('setProfile', state());
      commit('setGlobalFlash', res.flash, {root: true});
      return null;
    }
  },
  async logoutAsync({commit}) {
    await this._vm.api.auth.logoutAsync();
    this.commit('users/setUserList',[]);
    commit('setProfile', state());
    commit('setGlobalFlash', {successMessage: 'logout successful'}, {root: true});
  },
  async clearState({commit}) {
    commit('setProfile', state());
  }
};

export const getters = {
  isProfileLoaded(state) {
    // maybe compare whole state?
    return state.id !== -1;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
