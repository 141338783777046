<template>
  <div>
    <div class="justify-content-center">
      <flash-messages class="align-self-center" :messages="flash"></flash-messages>
    </div>
    <div class="d-flex justify-content-end">
      <pagination v-bind="namespacedPagination"></pagination>
    </div>
    <events-table v-if="eventsList.length !== 0" v-bind:rows="eventsList"></events-table>
    <div class="d-flex justify-content-end">
      <pagination v-bind="namespacedPagination"></pagination>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import namespacedQueryMixin from "../mixins/namespacedQueryMixin";

export default {
  name: "EventsComponent",
  mixins: [namespacedQueryMixin],
  props: {
    namespaceName: {
      default: null,
      type: String,
    },
    namespace: {
      default: false,
      type: Boolean,
    },
    type: {
      default: null,
      type: String
    },
    id: {
      type: [Number, String],
      default: 0
    },
  },
  data() {
    return {
      defaultNamespace: 'events',
      eventsList: [],
      pagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
      },
      flash: {},
      isShowModal: false,
      preventFilterClose: false,
    }
  },
  watch: {
    projectId() {
      this.loadEventListAsync(this.$route.query);
    },
    '$route'(n) {
      this.loadEventListAsync(n.query);
    },
  },
  async created() {
    await this.getUserListAsync();
    await this.loadEventListAsync(this.$route.query);
  },
  computed: {
    ...mapState('users', {userList: 'list'}),
  },
  methods: {
    ...mapActions('users', {getUserListAsync: 'getListAsync'}),
    async loadEventListAsync(query) {
      const parsedQuery = this.namespaceQueryFilter(query);
      // building request suitable query parameters
      const requestQuery = {
        limit: this.$options.data.apply(this).pagination.pageSize,
        page: 1,
      };
      if (parsedQuery.pageSize) {
        requestQuery.limit = parseInt(parsedQuery.pageSize);
      }
      if (parsedQuery.page) {
        requestQuery.page = parseInt(parsedQuery.page);
      }
      // waiting for request
      let res = null;
      switch (this.type) {
        case "uploads":
          res = await this.api.uploads.getEvents(this.id, requestQuery);
          break;
        case 'conversions':
          res = await this.api.conversions.getConversionEvents(this.id, requestQuery);
          break;
        case 'projects':
          res = await this.api.projects.getProjectEvents(this.id, requestQuery);
          break;
        default:
          res = {ok: false, flash: {generalErrors: [{message: "Non valid type"}]}}
          break;
      }
      // handling request
      if (res.ok) {
        this.eventsList = res.content.list;
        this.pagination = res.content.pagination;
      } else {
        this.flash = res.flash;
      }
    },
  }
}
</script>

<style scoped>

</style>