<template>
  <filter-form v-bind:title="$t('conversions.filter.title')"
               v-on:select-filter="selectFilter"
               v-on:clear-filter="clearFilter"
               v-on:prevent-filter-close=""
               v-on:close="$emit('close')">
    <div class="row my-2">
      <div class="col-lg-2">
        <h5 v-text="$t('conversions.filter.projects.field')"></h5>
      </div>
      <div class="col-lg-10">
        <project-selector
            v-model="selectedProjectId"
            @open="vueMultiSelectOpen"
            @close="vueMultiSelectClose">
        </project-selector>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-lg-2">
        <h5 v-text="$t('conversions.filter.converter.field')"></h5>
      </div>
      <div class="col-lg-10">
        <multiselect
            v-model="selectedUsers"
            v-bind:options="userList"
            v-bind:searchable="true"
            v-bind="multiselectI18n()"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            v-bind:placeholder="$t('conversions.filter.converter.placeholder')"
            :customLabel="customLabelUser"
            track-by="id"
            @open="vueMultiSelectOpen"
            @close="vueMultiSelectClose"
            :closeOnSelect="true">
        </multiselect>
      </div>
    </div>
    <div class="row my-2">
      <div class="col-lg-2">
        <h5 v-text="$t('file.filter.states.field')"></h5>
      </div>
      <div class="col-lg-10">
        <multiselect
            v-model="selectedStates"
            v-bind:options="states"
            v-bind:searchable="false"
            v-bind="multiselectI18n()"
            :multiple="true"
            :close-on-select="false"
            :clear-on-select="false"
            :preserve-search="true"
            v-bind:placeholder="$t('file.filter.states.placeholder')"
            @open="vueMultiSelectOpen"
            @close="vueMultiSelectClose"
            :closeOnSelect="true"
        ></multiselect>
      </div>
    </div>
  </filter-form>
</template>

<script>
import {mapState} from "vuex";
import queryFilterMixin from "../../../mixins/filterFormMixin";
import multiselectI18nMixin from "../../../mixins/multiselectI18nMixin";

export default {
  name: "PConversionsListFilterForm",
  data() {
    return {
      selectedUsers: [],
      states: ['CONVERTING', 'CONVERTED', 'ERROR_ON_CONVERT', 'CONVERTED_WITH_MISSING'],
      selectedStates: [],
      selectedProjectId: null,
      releaseStates: [{name: "RELEASED", value: true}, {name: "NOT_RELEASED", value: false}],
      selectedReleaseState: null,
    }
  },
  mixins: [queryFilterMixin, multiselectI18nMixin],
  async created() {
    // filling search on query
    if (this.$route.query.users) {
      // turning string of ids into list of selected users
      const usersIds = this.$route.query.users.split(',');
      this.selectedUsers = this.userList.filter(user => usersIds.some(id => parseInt(id) === user.id));
    }
    // filling search on query
    if (this.$route.query.states) {
      this.selectedStates = this.$route.query.states.split(',');
    }

    if (this.$route.query.projectId) {
      this.selectedProjectId = this.$route.query.projectId;
    }
    if (this.$route.query.release) {
      const h = !!this.$route.query.release;
      this.selectedReleaseState = this.releaseStates[h ? 0 : 1]
    }
  },
  methods: {
    selectFilter() {
      let queryToAdd = {
        states: this.selectedStates.toString(),
        users: this.selectedUsers.map(user => user.id).toString(),
        projectId: this.selectedProjectId,
        release: this.selectedReleaseState ? this.selectedReleaseState.value : null,
        page: 1,
      };
      this.$router.push({
        query: Object.assign({}, this.$route.query, queryToAdd)
      });
      this.isShowModal = false;
      this.$emit('close');
    },
  },
  computed: {
    ...mapState('users', {userList: 'list'}),
    ...mapState('projects', {projectList: 'list'}),
  },
}
</script>

<style scoped>

</style>