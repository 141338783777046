<template>
  <table class="table table-bordered">
    <tr>
      <th v-text="$t('file.name')"></th>
      <th v-text="$t('file.state')"></th>
      <th v-text="$t('project.project')"></th>
      <th v-text="$t('file.uploadedAt')"></th>
      <th v-text="$t('file.uploadedBy')"></th>
    </tr>
    <tr v-for="row in rows">
      <td>
        <router-link
          :to="{name:'uploaded-file', params: { token: row.id} }"
          active-class="active"
          v-bind:title="row.name"
          v-text="row.name">
        </router-link>
      </td>
      <td>
        <file-state v-bind:state="row.state"></file-state>
      </td>
      <td>
        <project-code v-bind:id="row.projects" :show-name="true"></project-code>
      </td>
      <td>
        {{ row.createdAt | stringDateToLocalDateTimeString }}
      </td>
      <td>
        <user-name v-bind:id="row.createdBy"></user-name>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "UploadedFilesTable",
  props: {
    rows: {
      type: Array,
      default: []
    },
  },
}
</script>

<style scoped>

</style>
