export const state = () => ({
  list: [],
});

export const mutations = {
  setProjectList(s, content) {
    s['list'] = content;
  },
};

export const actions = {
  async getListAsync({state: s, dispatch}) {
    // only running if we got nothing loaded
    if (s['list'].length === 0) {
      await dispatch('updateListAsync');
    }
  },
  async updateListAsync({commit}) {
    const res = await this._vm.api.projects.getProjectCodesListAsync();
    if (res.ok) {
      commit('setProjectList', res.content);
    } else {
      // TODO: IH: some flash maybe?
    }
  },
  async getProjectByIdAsync({state: s, dispatch}, projectId) {
    const id = parseInt(projectId);
    const project = s['list'].find(project => project.id === id);
    if (project) {
      return project;
    }
    // maybe that is a new project that is not yet in our system
    await dispatch('updateListAsync');
    return s['list'].find(project => project.id === id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
