import BTextInput from './B-TextInput.vue';
import BCheckbox from './B-Checkbox.vue';
import BFormGroup from './B-FormGroup.vue';
import Overlay from './Overlay.vue';
import Modal from './Modal.vue';
import Pagination from './Pagination';
import ButtonToggle from './ButtonToggle';
import FlashMessages from './FlashMessages.vue';
import EventList from './EventList';
import EventsTable from './EventsTable';
import EventsComponent from './EventsComponent';
import FilePreview from './FilePreview';
import UploadFile from './UploadFile';
import FileInfo from './FileInfo';
import FileState from './FileState';
import UserName from './UserName';
import FilterForm from './FilterForm';
import ProjectCode from './ProjectCode';
import ProjectSelector from './ProjectSelector';
import NavBack from './NavBack';

export default {
  install(Vue) {
    Vue.component('Pagination', Pagination);
    Vue.component('ButtonToggle', ButtonToggle);
    Vue.component('BTextInput', BTextInput);
    Vue.component('BCheckbox', BCheckbox);
    Vue.component('BFormGroup', BFormGroup);
    Vue.component('Modal', Modal);
    Vue.component('Overlay', Overlay);
    Vue.component('FlashMessages', FlashMessages);
    Vue.component('EventList', EventList);
    Vue.component('EventsTable', EventsTable);
    Vue.component('EventsComponent', EventsComponent);
    Vue.component('FilePreview', FilePreview);
    Vue.component('UploadFile', UploadFile);
    Vue.component('FileInfo', FileInfo);
    Vue.component('FileState', FileState);
    Vue.component('UserName', UserName);
    Vue.component('FilterForm', FilterForm);
    Vue.component('ProjectCode', ProjectCode);
    Vue.component('ProjectSelector', ProjectSelector);
    Vue.component('NavBack', NavBack);
  }
};