export default {
  data() {
    return {
      total: 0,
      found: 0,
      preventFilterClose: false,
      isShowModal: false,
    };
  },
  methods: {
    preventFilterCloseEventHandler(value) {
      this.preventFilterClose = value;
    },
    handleListRequest(res){
      if (res.ok) {
        this.list = res.content.list;
        this.pagination = res.content.pagination;
        this.found = this.pagination.totalItems;
        if (res.content.total){
          this.total = res.content.total;
        } else {
          // if total missing - assume total and found equal
          console.warn('Filtered paged requests did not give total!');
          this.total = this.found;
        }
      } else {
        this.flash = res.flash;
      }
    },
  },
  computed: {
    isFilterActive() {
      return this.found !== this.total;
    },
    filterButtonText() {
      if (this.isFilterActive) {
        return this.$t('filter.btn-filter-active', {found: this.found, total: this.total});
      } else {
        return this.$t('filter.btn-filter');
      }
    },
    clearFilterButtonText() {
      return this.$t('filter.btn-clear');
    }
  }
};
