export const state = () => ({
  list: [],
});

export const mutations = {
  setUserList(s, content) {
    s['list'] = content;
  },
};

export const actions = {
  async getListAsync({state: s, dispatch}) {
    // only running if we got nothing loaded
    if (s['list'].length === 0) {
      await dispatch('updateListAsync');
    }
  },
  async updateListAsync({commit}) {
    const res = await this._vm.api.users.getUserListAsync();
    if (res.ok) {
      commit('setUserList', res.content);
    } else {
      // TODO: IH: some flash maybe?
    }
  },
  async getUserByIdAsync({state: s, dispatch}, userId) {
    const id = parseInt(userId);
    const user = s['list'].find(user => user.id === id);
    if (user) {
      return user;
    }
    // maybe that is a new user that is not yet in our system
    await dispatch('updateListAsync');
    return s['list'].find(user => user.id === id);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
