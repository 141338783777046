<template>
  <table class="table table-xs mb-2">
    <tr>
      <!--      <th v-text="'id'"></th>-->
      <th v-text="$t('events.type')"></th>
      <th v-text="$t('events.created-at')"></th>
      <th v-text="$t('events.created-by')"></th>
    </tr>
    <tr v-for="row in rows">
      <!--      <td v-text="row.id"></td>-->
      <td v-text="row.type"></td>
      <td>
        {{ new Date(row.createdAt).toLocaleString() }}
      </td>
      <td>
        <user-name v-bind:id="row.createdBy"></user-name>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "EventsTable",
  props: {
    rows: {
      type: Array,
      default: []
    },
  },
}
</script>

<style scoped>
.table-xs {
  font-size: 12px !important;
  font-family: 'Verdana', monospace !important;
}

.table-xs th, .table-xs td {
  padding: 0 2px !important;
}
</style>