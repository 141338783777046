<template>
  <div class="btn-group btn-group-toggle" data-toggle="buttons">
    <label v-bind:class="isPrimaryButton(!toggle)">
      <input type="radio" v-on:click="clickLeft"> {{buttonLeft}}
    </label>
    <label v-bind:class="isPrimaryButton(toggle)">
      <input type="radio" v-on:click="clickRight"> {{buttonRight}}
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      buttonLeft: {
        type: String,
        default: "buttonLeft",
      },
      buttonRight: {
        type: String,
        default: "buttonRight",
      },
      toggle: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      clickLeft(){
        this.$emit('update', false);
      },
      clickRight(){
        this.$emit('update', true);
      },
      isPrimaryButton(param) {
        if (param) {
          return 'border-dark btn-primary btn btn-sm';
        } else {
          return 'border-dark btn-light btn btn-sm';
        }
      },
    },
  }
</script>