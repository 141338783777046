import http from './index';

const requests = function (axiosInstance) {
  return {
    checkConversionAsync: async (token, query = {}) => {
      return axiosInstance.get('/conversions/' + token + '/check', {params: query});
    },
    getConversionMetaAsync: async (id) => {
      return axiosInstance.get('/conversions/' + id + '/meta');
    },
    getConversionEvents: async (id, query = {}) => {
      return axiosInstance.get('/conversions/' + id + '/events', {params: query});
    },
    getConversionSamplesAsync: async (id) => {
      return axiosInstance.get('/conversions/' + id + '/samples');
    },
    getConversionPeopleAsync:  async (id) => {
      return axiosInstance.get('/conversions/' + id + '/people');
    },
    getConvertedAsync: async (id) => {
      return axiosInstance.get('/conversions/' + id);
    },
    getConvertedStatesCountsAsync: async (query = {}) => {
      return axiosInstance.get('/conversions/states', {params: query});
    },
    getConvertedPreview: async (id, type, query = {}) => {
      return axiosInstance.get('/conversions/' + id + '/preview/' + type, {params: query});
    },
    getConversionsListAsync: async (query = {}) => {
      return axiosInstance.get('/conversions', {params: query});
    },
    setReleaseState: async (id, body) => {
      return axiosInstance.post('/conversions/' + id + '/release', body);
    },
    attachToProject(uploadId, projectId) {
      return axiosInstance.post('/conversions/' + uploadId + '/project', {projectId: projectId});
    },
    transformResultIntoSource(id) {
      return axiosInstance.post('/conversions/' + id + '/to-source');
    }
  };
};
export default {
  ...requests(http)
};

module.exports.conversionRequests = requests;
