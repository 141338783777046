<template>
  <div class="in-modal p-3">
    <div class="d-flex mb-4">
      <div class="col pl-0">
        <span class="h4" v-text="title"></span>
      </div>
      <div>
        <font-awesome-icon icon="times" @click="$emit('close')"></font-awesome-icon>
      </div>
    </div>
    <slot></slot>
    <div class="row my-2">
      <div class="col-8"></div>
      <div class="col-lg-2 mt-2">
        <button class="btn btn-block btn-outline-danger" v-on:click="$emit('clear-filter')"
                v-text="$t('filter.btn-clear')"></button>
      </div>
      <div class="col-lg-2 mt-2">
        <button class="btn btn-primary btn-block" v-on:click="$emit('select-filter')"
                v-text="$t('filter.btn-filter')"></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterForm",
  props: {
    title: {
      type: String,
      default: ''
    },
  },
}
</script>

<style scoped>
.in-modal {
  /*width: 1024px;*/
  /*min-width: 1024px;*/
  /*background: white;*/
  /*border-radius: 6px;*/
  /*margin-top: 10%;*/
  /*margin-bottom: auto;*/

  width: 1024px;
  max-width: 1024px;
  background: white;
  border-radius: 6px;
  margin: 10% 5% auto;
}
</style>