export default {
  methods: {
    clearFilter() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.$emit('clear-filter');
    },
    customLabelUser(user) {
      return this.$options.filters.userToName(user);
    },
    customLabelProject(project) {
      return project.code;
    },
    vueMultiSelectOpen() {
      this.$emit('prevent-filter-close', true);
    },
    vueMultiSelectClose() {
      this.$emit('prevent-filter-close', false);
    },
  },
};