import http from './index';

const requests = function (axiosInstance) {
  return {
    uploadAsync: async (files) => {
      return axiosInstance.post('/uploads', files);
    },
    loadPreUploadAsync: async (fromData) => {
      return axiosInstance.post('/load-pre-upload', fromData);
    },
    modifyAsync: async (uploadId, query = {}) => {
      return axiosInstance.post('/uploads/' + uploadId + '/convert', query);
    },
    getPreview: async (uploadId, query = {}) => {
      return axiosInstance.get('/uploads/' + uploadId + '/preview', {params: query});
    },
    getInfo: async (uploadId) => {
      return axiosInstance.get('/uploads/' + uploadId);
    },
    getEvents: async (uploadId, query = {}) => {
      return axiosInstance.get('/uploads/' + uploadId + '/events', {params: query});
    },
    getConversionsListAsync: async (id, query = {}) => {
      return axiosInstance.get('/uploads/' + id + '/conversions', {params: query});
    },
    getFileListAsync: async (query = {}) => {
      return axiosInstance.get('/uploads', {params: query});
    },
    getStatesCountsAsync: async (query = {}) => {
      return axiosInstance.get('/uploads/states', {params: query});
    },
    attachToProject(uploadId, projectId) {
      return axiosInstance.post('/uploads/' + uploadId + '/project', {projectId: projectId});
    },
    getConversionCodesPreview: async (uploadId, genType, genColumn, personCodeGroup, sampleCodeGroup) => {
      const body = {
        genType,
        genColumn,
        personCodeGroup,
        sampleCodeGroup,
      };
      return axiosInstance.post(`/uploads/${uploadId}/convert-preview`, body);
    }
  };
};
export default {
  ...requests(http)
};

module.exports.uploadsRequests = requests;
