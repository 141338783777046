<template>
  <div>
    <div class="justify-content-center">
      <flash-messages class="align-self-center" :messages="flash"></flash-messages>
    </div>
    <div class="d-flex justify-content-end">
      <pagination v-bind="pagination"></pagination>
      <button class="btn btn-primary btn-xs" v-on:click="isShowModal = true" v-text="filterButtonText"></button>
      <button class="btn btn-primary btn-xs" v-if="isFilterActive" v-on:click="clearFilters" v-text="clearFilterButtonText"></button>
    </div>
    <conversions-table v-if="list.length !== 0" v-bind:rows="list"></conversions-table>
    <div class="d-flex justify-content-end">
      <pagination v-bind="pagination"></pagination>
      <button class="btn btn-primary btn-xs" v-on:click="isShowModal = true" v-text="filterButtonText"></button>
      <button class="btn btn-primary btn-xs" v-if="isFilterActive" v-on:click="clearFilters" v-text="clearFilterButtonText"></button>
    </div>
    <modal
        v-if="isShowModal"
        v-bind:preventClose="preventFilterClose"
        v-on:close="isShowModal = false">
      <conversions-list-filter-form
          slot="override"
          v-on:close="isShowModal = false"
          v-on:prevent-filter-close="preventFilterCloseEventHandler">
      </conversions-list-filter-form>
    </modal>
  </div>
</template>

<script>
import ConversionsTable from "./ConversionsTable";
import filteredListComponentMixin from "../../../mixins/filteredListComponentMixin";
import ConversionsListFilterForm from "./ConversionsListFilterForm";
export default {
  name: "ConversionsListComponent",
  components: {ConversionsListFilterForm, ConversionsTable},
  mixins: [filteredListComponentMixin],
  data() {
    return {
      list: [],
      pagination: {
        page: 1,
        pageSize: 20,
        totalItems: 0,
      },
      flash: {},
    }
  },
  watch: {
    '$route'(n) {
      this.loadConversionsListAsync(n.query);
    }
  },
  async created() {
    await this.loadConversionsListAsync(this.$route.query);
  },
  methods: {
    async loadConversionsListAsync(query) {
      // building request suitable query parameters
      const requestQuery = {
        limit: this.$options.data.apply(this).pagination.pageSize,
        page: 1,
      };
      if (query.pageSize) {
        requestQuery.limit = parseInt(query.pageSize);
      }
      if (query.page) {
        requestQuery.page = parseInt(query.page);
      }
      if (query.states) {
        requestQuery.states = query.states;
      }
      if (query.users) {
        requestQuery.users = query.users;
      }
      if (query.projectId) {
        requestQuery.projectId = query.projectId;
      }
      if (query.release) {
        requestQuery.release = query.release;
      }
      if (query.ids) {
        requestQuery.ids = query.ids;
      }
      // waiting for request
      const res = await this.api.conversions.getConversionsListAsync(requestQuery);
      // handling request
      this.handleListRequest(res);
    },
    clearFilters() {
      this.$router.push({
        query: Object.assign({}, this.$route.query, {
          states: null,
          users: null,
          projectId: null,
          release: null,
          page: 1,
        })
      });
    },
  }
}
</script>

<style scoped>
.btn-xs {
  font-size: 0.875rem;
  line-height: 1;
  height: 26px;
  margin: .25rem;
  padding: 2px 12px;
}
</style>
