import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import profileStore from './profile';
import userStore from './users';
import projectStore from './projects';

export default new Vuex.Store({
  modules: {
    profile: profileStore,
    users: userStore,
    projects: projectStore,
  },
  state: {
    features: null,
    version: null,
    globalMessage: {}
  },
  mutations: {
    setGlobalFlash(s, flash) {
      console.log('setGlobalFlash', flash);
      s.globalMessage = flash;
    },
    clearGlobalFlash(s) {
      console.log('clearingGlobalFlash');
      s.globalMessage = {};
    },
    setFeatures(s, conf) {
      console.log(conf);
      s.features = conf.features;
      s.version = conf.version;
    },
  },
  actions: {
    async loadFeatures({state: s, commit}, force = false) {
      if (s.features && !force) return;

      try {
        const res = await this._vm.api.http.get('/features');
        if (res.ok) commit('setFeatures', res.content);
        else commit('setGlobalFlash', res.flash);
      } catch (e) {
        return {
          ok: false,
          flash: {
            generalErrors: [
              {type: 'frontend-error', msg: e.message}
            ]
          }
        };
      }
    }
  }
});