<template>
  <div class="form-check b-checkbox b-input">
    <label class="form-check-label">
      <input type="checkbox" class="form-check-input"
             :checked="checked"
             @click="update">
      {{label}}
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      value: {
        type: [Boolean, Number, String, Array],
      },
      label: {
        type: String,
        default: '',
      },
      trueValue: {
        default: undefined,
      },
      falseValue: {
        default: undefined,
      },
    },
    computed: {
      checked() {
        const {value, trueValue, falseValue, label} = this;
        const key = trueValue || label;
        if (Array.isArray(value) && value.includes(key)) {
          return true;
        }
        if (typeof value === 'string' && (value === 'True' || value === 'T' || value === trueValue)) {
          return true;
        }
        if (trueValue !== undefined && falseValue !== undefined) {
          return value === trueValue;
        }
        return false;
      },
    },
    methods: {
      update(e) {
        const vm = this;
        const v = e.target.checked;
        const {value, trueValue, falseValue, label} = this;
        if (Array.isArray(value) && v === true) {
          this.$emit('input', [...value, trueValue || label]);
          return;
        }
        if (Array.isArray(value) && v === false) {
          this.$emit('input', value.filter((i) => {
            if (trueValue) {
              return i !== trueValue;
            }
            return i !== label;
          }));
        }
        if (trueValue !== undefined && falseValue !== undefined) {
          v ? vm.$emit('input', trueValue) : vm.$emit('input', falseValue);
        }
      },
    },
  };
</script>