import './assets/bootstrap.scss';
import './assets/transitions.css';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'vue-multiselect/dist/vue-multiselect.min.css';

import Vue from 'vue';
import i18n from './i18n/index';

// Fontawesome
import FontAwesome from '@fortawesome/fontawesome';
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
import FontAwesomeFreeSolid from '@fortawesome/fontawesome-free-solid';

FontAwesome.library.add(FontAwesomeFreeSolid); // Use any icon from the Solid style
Vue.component('FontAwesomeIcon', FontAwesomeIcon); // Use the icon component anywhere in the app

// adding multi-select
import Multiselect from 'vue-multiselect';
Vue.component('Multiselect', Multiselect);

// adding components
import components from './components';
Vue.use(components);

// adding layout components
import layouts from './layouts';
Vue.use(layouts);

// adding filters
import filters from './filters';
Vue.use(filters);

import WebApp from './modules/WebApp.vue';

import router from './routes';
import store from './store';

// Authorization
const can = (user) => (...permissions) => {
  let answer = false;
  let len = permissions.length;
  for (let i = 0; i < len; i += 1) {
    let perm = permissions[i];
    if (typeof perm === 'string') {
      if (user().permissions.includes(perm)) {
        answer = true;
        break;
      }
    }
    if (typeof perm === 'function') {
      if (perm(user())) {
        answer = true;
        break;
      }
    }
  }
  return answer;
};

Vue.prototype.$can = can(() => store.state.profile);

import api from './plugins/axios';
Vue.use(api, {store, router});

new Vue(
  {
    components: {
      WebApp,
    },
    i18n,
    router,
    store,
    render: h => h(WebApp),
  }
).$mount('#app');