export default {
  methods: {
    multiselectI18n(options = {}) {
      const {allowEmpty} = options;
      const labels = {
        deselectLabel: this.$t('multiselect.deselectLabel'),
        selectLabel: this.$t('multiselect.selectLabel'),
        selectedLabel: this.$t('multiselect.selectedLabel'),
      };
      if (allowEmpty !== undefined && !allowEmpty){
        labels.deselectLabel = '';
      }
      return labels;
    },
  },
};