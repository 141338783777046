<template>
  <div>
    <div class="btn-group rounded d-flex">
      <button v-for="i in $i18n.availableLocales"
              class="btn col"
              type="button"
              :class="selectedBtnClasses($i18n.locale === i)"
              @click="setLanguage(i)"
              v-text="$i18n.messages[i].value"
      ></button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "languageSelect",
    data() {
      return {}
    },
    methods: {
      setLanguage(language){
        localStorage.language = language;
        this.$root.$i18n.locale = language;
      },
      selectedBtnClasses(isSelected) {
        return {
          "btn-outline-light": isSelected,
          "btn-outline-secondary": !isSelected,
        };
      },
    }
  }
</script>

<style scoped>

</style>