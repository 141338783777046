import http from './index';

const requests = function (axiosInstance) {
  return {
    getCurrentUserAsync: async () => {
      return axiosInstance.get('/auth/current');
    },
    startMobileIdLoginAsync: async (idCode, phoneNo) => {
      return axiosInstance.post('/login/mobile', {phoneNo, idCode});
    },
    checkMobileIdLoginAsync: async (sessionCode) => {
      return axiosInstance.post('/login/mobile/check', {sessionCode});
    },
    startSmartIdLoginAsync: async (idCode) => {
      return axiosInstance.post('/login/smart-id', {idCode});
    },
    checkSmartIdLoginAsync: async (sessionCode) => {
      return axiosInstance.post('/login/smart-id/check', {sessionCode});
    },
    idCardLoginAsync: async () => {
      // TODO: 04.01.2022 14:39 IHa test subdomain or just pure thing - never should be called outside of id. subdmain
      // return axiosInstance.get('https://id.releases.justme/api/login/id-card');
      return axiosInstance.get('/login/id-card');
    },
    logoutAsync() {
      return axiosInstance.post('/logout');
    }
  };
};
export default {
  ...requests(http)
};

module.exports.authenticationRequests = requests;