<template>
  <input :value="value" class="form-control" v-on="inputListeners" :class="classes"/>
</template>

<script>
  export default {
    name: 'b-text-input',
    props: {
      value: {
        type: String,
        default: '',
      },
    },
    computed: {
      classes() {
        // const vm = this;
        // if (vm.validation && vm.validationKey) {
        //   return {
        //     'is-valid': vm.validation.isPassed(vm.validationKey),
        //     'is-invalid': vm.validation.hasError(vm.validationKey)
        //   }
        // }
        return null;
      },
      inputListeners() {
        const vm = this;
        return Object.assign(
          {},
          vm.$listeners,
          {
            // This ensures that the component works with v-model
            input: function (event) {
              vm.$emit('input', event.target.value)
            }
          })
      }
    }
  }
</script>