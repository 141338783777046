<template>
  <table class="table table-bordered">
    <tr>
<!--      <th>Id</th>-->
      <th v-text="$t('file.name')" class="col-6"></th>
      <th v-text="$t('file.state')"></th>
      <th v-text="$t('project.project')"></th>
      <th v-text="$t('file.info')"></th>
      <th v-text="$t('file.convertedAt')"></th>
      <th v-text="$t('file.convertedBy')"></th>
    </tr>
    <tr v-for="row in rows">
<!--      <td>-->
<!--        <router-link-->
<!--            :to="{name:'convert-result', params: { conversionToken: row.id} }"-->
<!--            active-class="active"-->
<!--            v-bind:title="row.name"-->
<!--            v-text="row.id">-->
<!--        </router-link>-->
<!--      </td>-->
      <td>
        <router-link
          :to="{name:'convert-result', params: { conversionToken: row.id} }"
          active-class="active"
          v-bind:title="row.releaseName || row.id"
          v-text="row.releaseName || row.id"
          class=".text-break">
        </router-link>
      </td>
      <td>
        <file-state v-bind:state="row.state"></file-state>
      </td>
      <td>
        <project-code v-bind:id="row.projects"></project-code>
      </td>
      <td>
        <font-awesome-icon icon="user"></font-awesome-icon>
        {{ row.people }}
        <font-awesome-icon icon="vial"></font-awesome-icon>
        {{ row.samples }}
      </td>
      <td>
        {{ row.createdAt | stringDateToLocalDateTimeString }}
      </td>
      <td>
        <user-name v-bind:id="row.createdBy"></user-name>
      </td>
    </tr>
  </table>
</template>

<script>
export default {
  name: "ConversionsTable",
  props: {
    rows: {
      type: Array,
      default: []
    },
    conversionFinishCheck: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      flash: {}
    }
  },
  watch: {
    rows(newValue) {
      if (this.conversionFinishCheck && newValue.length > 0) {
        this.checkConversionState();
      }
    }
  },
  methods: {
    checkConversionState() {
      const listOfConverting = this.rows.flatMap(row => (row.state === 'CONVERTING' ? [row.id] : []));
      listOfConverting.forEach(id => this.checkAsync(id));
    },
    async checkAsync(id) {
      while (true) {
        let res = await this.api.conversions.checkConversionAsync(id);
        if (res.ok) {
          if (res.content.state !== "CONVERTING") {
            this.$emit('reload', res.content);
            break;
          }
        } else {
          this.flash = res.flash;
          break;
        }
      }
    },
  },
}
</script>

<style scoped>

</style>
