<template>
  <div>
    <h3 v-text="$t('upload.new')"></h3>
    <div class="form-group">
      <flash-messages class="align-self-center" :messages="flash"></flash-messages>
    </div>
    <form>
      <div class="form-group">
        <div class="custom-file">
          <input
              type="file"
              class="custom-file-input"
              v-on:change="handleFile"
              v-text="$t('upload.browse')"
          >
          <label class="custom-file-label"
                 v-text="fileToUpload ? fileToUpload.name : $t('upload.choose')"></label>
        </div>
      </div>
      <button
          class="btn btn-primary"
          type="button"
          v-on:click="uploadFileAsync"
          v-bind:disabled="fileBeingSent"
          v-text="$t('upload.upload')"
      ></button>
    </form>
  </div>
</template>

<script>
export default {
  name: "UploadFile",
  props: {
    projectId: {
      type: [Number,String],
      default: -1
    },
  },
  data() {
    return {
      fileToUpload: null,
      filePreview: null,
      fileBeingSent: false,
      flash: {}
    }
  },
  methods: {
    async handleFile(e) {
      const files = e.target.files;
      // make it work with more than one file???
      this.fileToUpload = files[0];
    },
    isValidFile(file) {
      return file.name.endsWith('txt') || file.name.endsWith('csv') || file.name.endsWith('.gz') || file.name.endsWith('.zip');
    },
    async uploadFileAsync() {
      if (this.fileToUpload) {
        const formData = new FormData();
        formData.append('file', this.fileToUpload);
        this.fileBeingSent = true;
        let res;
        // if projectId given then we upload file under the project
        if (this.projectId === -1){
          res = await this.api.uploads.uploadAsync(formData);
        } else {
          res = await this.api.projects.uploadAsync(this.projectId, formData);
        }
        if (res.ok) {
          this.$emit('input', res.content);
        } else {
          this.flash = res.flash;
        }
        this.fileBeingSent = false;
      } else {
        this.flash = {fieldErrors: [{param: 'upload.new', msg: this.$t('upload.choose')}]};
      }
    },
  }
}
</script>

<style scoped>

</style>