<template>
  <div>
    <slot></slot>
    <div class="justify-content-center">
      <flash-messages class="align-self-center" :messages="flash"></flash-messages>
    </div>
    <div v-if="isLoading">
      <div class="text-center flex-fill">
        <div class="m-5">
          <font-awesome-icon icon="spinner" spin size="6x"></font-awesome-icon>
        </div>
        <h3 class=m-5">
          {{ $t('converter.inProgress') }}
        </h3>
      </div>
    </div>
    <transition v-else name="appear-h">
      <table class="table table-sm table-bordered table-responsive table-sm table-text-truncate">
        <tr>
          <th v-for="head in headers">{{ head }}</th>
        </tr>
        <tr v-for="row in rows">
          <td v-for="cell in row">{{ cell }}</td>
        </tr>
      </table>
    </transition>
  </div>
</template>

<script>
export default {
  name: "FilePreview",
  props: {
    type: {
      type: String,
      default: null,
    },
    id: {
      type: Number,
      default: null,
    }
  },
  data() {
    return {
      headers: [],
      rows: [],
      isLoading: true,
      flash: {},
    }
  },
  mounted() {
    this.getPreview();
  },
  methods: {
    async getPreview() {
      let res;
      if (this.type === "upload") {
        res = await this.api.uploads.getPreview(this.id);
      } else if (this.type === "release" || this.type === "complete") {
        res = await this.api.conversions.getConvertedPreview(this.id, this.type);
      } else if (this.type === "special") {
        res = await this.api.http.get("release/" + this.id + "/preview");
      } else {
        console.error('Wrong type for preview:', this.type);
        return;
      }
      if (res && res.ok) {
        this.headers = res.content.names;
        this.rows = res.content.rows;
      } else {
        this.flash = res.flash;
      }
      this.isLoading = false;
    }
  },
}
</script>

<style scoped>
.table-text-truncate th, .table-text-truncate td {
  white-space: nowrap;
}
</style>