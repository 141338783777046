import axios from 'axios';
import errorCodes from '../../api/errorCodes';

const axiosConfig = {
  baseURL: '/api',
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
};

const creatAxiosInstance = (responseInterceptorSuccess, responseInterceptorRejected) => {
  const axiosInstance = axios.create(axiosConfig);
  axiosInstance.interceptors.response.use(responseInterceptorSuccess, responseInterceptorRejected);
  return axiosInstance;
};

const responseInterceptorSuccess = (response) => {
  console.log('axios response', response);

  return {
    status: response.status,
    ok: response.data.ok,
    flash: response.data.flash || {},
    content: response.data.content
  };
};
const responseInterceptorRejected = (error) => {
  console.error('axios error', error);
  let flash = {generalErrors: [{type: 'axios-error', message: error.message, errorCode: errorCodes.ConnectionError}]};
  if (error.data && error.data.flash) {
    flash = error.data.flash;
  }
  return {
    status: error.status || -1,
    ok: false,
    flash: flash
  };
};

export default creatAxiosInstance(responseInterceptorSuccess,responseInterceptorRejected);

module.exports.customAxiosInstance = (customResponseInterceptorSuccess,customResponseInterceptorRejected) => creatAxiosInstance(customResponseInterceptorSuccess,customResponseInterceptorRejected);
module.exports.responseInterceptorSuccess = responseInterceptorSuccess;
module.exports.responseInterceptorRejected = responseInterceptorRejected;