<template>
  <a v-on:click="back"
     v-bind:title="$t('nav.back')"
     style="cursor: pointer;">
    <font-awesome-icon icon="arrow-left"></font-awesome-icon>
  </a>
</template>

<script>
export default {
  name: "NavBack",
  props: {
    fallbackLocation: {
      type: Object,
      default: {name: 'home'},
    },
  },
  methods: {
    back() {
      if (window.history.length > 2) {
        this.$router.go(-1);
      } else {
        this.$router.push(this.fallbackLocation);
      }
    },
  },
}
</script>

<style scoped>

</style>