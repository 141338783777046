import errorCodes from '../../api/errorCodes';
import {authenticationRequests} from '../api/authentication.api';
import {userRequests} from '../api/user.api';
import {uploadsRequests} from '../api/uploads.api';
import {projectsRequests} from '../api/project.api';
import {conversionRequests} from '../api/conversions.api';
import {customAxiosInstance,responseInterceptorRejected} from '../api/index';

export default {
  install: function (Vue, {store, router}) {
    const interceptorSuccess = async (response) => {
      if (!response.data.ok) {
        console.log(response.data.flash);
        if (response.data.flash && response.data.flash.generalErrors) {
          for (const error of response.data.flash.generalErrors) {
            console.log(error);
            if (error.errorCode) {
              // check if permission related -> reload permissions
              if (error.errorCode === errorCodes.InvalidPermission) {
                await store.dispatch('profile/reloadCurrentUser');
              }

              // check if authentication related AND there is active profile -> throw to "home"
              if (error.errorCode === errorCodes.UserAuthenticationRequired && store.getters['profile/isProfileLoaded']) {
                // clearing the profile and reload -> router sends to login while preserving the old route
                await store.dispatch('profile/clearState');
                router.go();
              }
            }
          }
        }
      }

      return {
        status: response.status,
        ok: response.data.ok,
        flash: response.data.flash || {},
        content: response.data.content
      };
    };

    const axiosInstance = customAxiosInstance(interceptorSuccess, responseInterceptorRejected);

    Vue.prototype.api = {
      http: axiosInstance,
      auth: authenticationRequests(axiosInstance),
      users: userRequests(axiosInstance),
      projects: projectsRequests(axiosInstance),
      uploads: uploadsRequests(axiosInstance),
      conversions: conversionRequests(axiosInstance),
    };
  }
};