<template>
  <span>
    {{ displayString }}
  </span>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "UserName",
  props: {
    id: {
      type: [Number, String],
      default: null
    },
  },
  data() {
    return {
      user: {}
    }
  },
  async mounted() {
    this.user = await this.getUserByIdAsync(this.id);
  },
  watch: {
    async id() {
      this.user = await this.getUserByIdAsync(this.id);
    }
  },
  methods: {
    ...mapActions('users', ['getUserByIdAsync']),
  },
  computed: {
    displayString() {
      return this.$options.filters.userToName(this.user);
    }
  }
}
</script>

<style scoped>

</style>