<template>
  <div class="navigation-layout">
    <nav class="navbar fixed-top navbar-expand-lg bg-primary navbar-dark">
      <a class="navbar-brand" href="#">{{ $t('nav.releases') }}</a>
      <button class="navbar-toggler" type="button" aria-controls="navbarTogglerDemo02" aria-expanded="false"
              aria-label="Toggle navigation" @click="isShowMenu = !isShowMenu">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" :class="{'show': isShowMenu}" id="navbarTogglerDemo02">
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item" v-if="pages.includes('home')">
            <router-link class="nav-link" :to="{name:'home'}" active-class="active" exact>{{ $t('nav.home') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="pages.includes('uploads-list') & $can('file:upload','file:convert')">
            <router-link class="nav-link" :to="{name:'uploads-list'}" active-class="active">
              {{ $t('nav.uploads-list') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="pages.includes('conversions-list') & $can('file:convert')">
            <router-link class="nav-link" :to="{name:'conversions-list'}" active-class="active">
              {{ $t('nav.conversions-list') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="pages.includes('projects-list') & $can('project:view')">
            <router-link class="nav-link" :to="{name:'projects-list'}" active-class="active">
              {{ $t('nav.projects-list') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="pages.includes('code-usage') & $can('code-usage:view')">
            <router-link class="nav-link" :to="{name:'code-usage'}" active-class="active">
              {{ $t('nav.code-usage') }}
            </router-link>
          </li>
          <li class="nav-item" v-if="pages.includes('users') & $can('users:manage')">
            <router-link class="nav-link" :to="{name:'user-list'}" active-class="active">{{ $t('nav.users') }}
            </router-link>
          </li>
        </ul>
        <br v-if="isShowMenu">
        <div class="px-xl-2 text-white" v-bind:class="{'py-2': isShowMenu}" v-text="$store.state.version"></div>
        <div class="px-xl-2" v-bind:class="{'py-2': isShowMenu}">
          <language-select></language-select>
        </div>
        <div class="px-xl-2 btn-group rounded d-flex" v-if="$store.state.profile.id !== -1">
          <button class="btn btn-outline-danger" type="button" @click="logoutAsync()">{{ $t('nav.logout') }}</button>
        </div>
      </div>
    </nav>
    <div class="content-fluid px-3">
      <flash-messages class="align-self-center" :messages="globalFlash"></flash-messages>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import LanguageSelect from '../components/Language';

export default {
  components: {LanguageSelect},
  data() {
    return {
      isShowMenu: false,
    };
  },
  created() {
    console.log('all-routes', this.$router.routes);
  },
  methods: {
    async logoutAsync() {
      await this.$store.dispatch('profile/logoutAsync');
      this.$router.push('/login');
    },
  },
  computed: {
    ...mapState({
      'globalFlash': 'globalMessage',
      'pages': s => s.features ? s.features.pages : [],
    }),
  },
};
</script>

<style scoped lang="scss">
//.navbar {
//  //background: #3f2bc7;
//}

.content-fluid {
  padding-top: 72px;
}
</style>
