<template>
  <div class="form-group" :class="classes">
    <label class="sr-only" v-if="placeholder">{{placeholder}}</label>
    <slot>
      <b-text-input :value="value" :placeholder="placeholder" v-on="inputListeners" :disabled="disabled"></b-text-input>
    </slot>
    <div class="invalid-feedback" v-if="showErrors">
      <p class="error-message" slot="error-message" v-for="e in validationErrors">{{e}}</p>
    </div>
  </div>
</template>

<script>
  import isString from 'lodash/isString';

  export default {
    props: {
      value: {},
      disabled: false,
      placeholder: {},
      isShowLabel: {},
    },
    computed: {
      classes() {
        return {
          'is-filled': (isString(this.value) && this.value.length > 0) || this.isShowLabel,
        }
      },
      showErrors() {
        return false;
      },
      validationErrors() {
        return [];
      },
      inputListeners() {
        const vm = this;
        return Object.assign(
          {},
          this.$listeners,
          {
            input(e) {
              // console.log('e', e);
              vm.$emit("input", e);
            }
          }
        );
      },
    }
  }
</script>

<style lang="scss">
  .form-group.is-filled {
    position: relative;

    label.sr-only {
      left: 2px;
      top: -14px;
      font-size: 11px;
      font-weight: bold;
      height: 8px !important;
      overflow: unset !important;
      clip: unset !important;
      color: #212529 !important;
    }
  }
</style>