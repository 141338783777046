<template>
    <multiselect
        v-model="selectedProject"
        v-bind:options="projectList"
        v-bind:searchable="true"
        v-bind="multiselectI18n({allowEmpty:false})"
        :multiple="false"
        :close-on-select="false"
        :clear-on-select="false"
        :preserve-search="true"
        v-bind:placeholder="$t('file.filter.projects.placeholder')"
        :customLabel="customLabelProject"
        @open="$emit('open')"
        @close="$emit('close')"
        @select="selectReEmit"
        track-by="id"
        :closeOnSelect="true"
    ></multiselect>
</template>

<script>
import {mapActions, mapState} from "vuex";
import multiselectI18nMixin from "../mixins/multiselectI18nMixin";

export default {
  name: "ProjectSelector",
  mixins: [multiselectI18nMixin],
  props: {
    value: {
      type: [Number, String],
      default: -1
    },
  },
  data() {
    return {
      selectedProject: null,
    }
  },
  async created() {
    await this.getProjectListAsync();
    this.idToProject();
  },
  watch: {
    value(){
      this.idToProject();
    },
    selectedProject(n, o) {
      if (n !== o) {
        if (n && n.id) {
          this.$emit("input", n.id);
        } else {
          // if cleared then valuate with null
          this.$emit("input", null);
        }
      }
    }
  },
  methods: {
    ...mapActions('projects', {getProjectListAsync: 'getListAsync'}),
    idToProject(){
      const project = this.projectList.find(p => p.id == this.value);
      if (project) {
        this.selectedProject = project;
      } else {
        this.selectedProject = null;
      }
    },
    customLabelProject(project) {
      if (project.id === -1){
        return '-'
      }
      return `${project.code} ${project.name}`;
    },
    selectReEmit(option){
      this.$emit('select', option);
    }
  },
  computed: {
    ...mapState('projects', {projectList: 'list'}),
  },
}
</script>

<style scoped>

</style>
