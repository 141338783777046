<template>
  <div>
    <div class="h3">{{ $t('home.eventHistory') }}</div>
    <div class="justify-content-center">
      <flash-messages class="align-self-center" :messages="flash"></flash-messages>
    </div>
    <div class="d-flex justify-content-end">
      <pagination v-bind="namespacedPagination"></pagination>
    </div>
    <table class="table table-xs mb-2">
      <tbody>
      <tr v-for="event in eventList">
        <td>{{ event.id }}</td>
        <td>{{ new Date(event.createdAt).toLocaleString() }}</td>
        <td>{{ event.type }}</td>
      </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <pagination v-bind="namespacedPagination"></pagination>
    </div>
  </div>
</template>

<script>
import namespacedQueryMixin from "../mixins/namespacedQueryMixin";

export default {
  name: "EventList",
  mixins: [namespacedQueryMixin],
  props: {
    namespaceName: {
      default: null,
      type: String,
    },
    namespace: {
      default: false,
      type: Boolean,
    },
    userId: {
      default: null,
      type: [String, Number],
    },
  },
  data() {
    return {
      defaultNamespace: 'events',
      eventList: [],
      pagination: {
        page: 1,
        pageSize: 50,
        totalItems: 0,
      },
      flash: {},
    }
  },
  watch: {
    '$route'(n) {
      this.loadUserEventListAsync(n.query);
    }
  },
  created() {
    this.loadUserEventListAsync(this.$route.query);
  },
  methods: {
    async loadUserEventListAsync(query) {
      const parsedQuery = this.namespaceQueryFilter(query);
      // building request suitable query parameters
      const requestQuery = {
        limit: this.$options.data.apply(this).pagination.pageSize,
        page: 1,
      };

      if (parsedQuery.pageSize) {
        requestQuery.limit = parseInt(parsedQuery.pageSize);
      }

      if (parsedQuery.page) {
        requestQuery.page = parseInt(parsedQuery.page);
      }

      const id = this.userId || this.$store.state.profile.id;

      // waiting for request
      const res = await this.api.users.getUserEventsAsync(id, requestQuery);
      if (res.ok) {
        this.eventList = res.content.list;
        this.pagination = res.content.pagination;
      } else {
        this.flash = res.flash;
      }
    },
  },
}
</script>

<style scoped>
.table-xs {
  font-size: 12px !important;
  font-family: 'Verdana', monospace !important;
}

.table-xs th, .table-xs td {
  padding: 0 2px !important;
}

</style>