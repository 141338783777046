import http from './index';

const requests = function (axiosInstance) {
  return {
    getUserAsync(userId) {
      return axiosInstance.get('/users/' + userId);
    },
    getUserListAsync() {
      return axiosInstance.get('/users');
    },
    getPermissionsListAsync() {
      return axiosInstance.get('/users/permissions');
    },
    getUserLisAdminAsync(query = {}) {
      return axiosInstance.get('/users/admin', {params: query});
    },
    getUserEventsAsync(userId, query = {}) {
      return axiosInstance.get('/users/' + userId + '/events', {params: query});
    },
    generateUsersApiKeyAsync(userId) {
      return axiosInstance.post('/users/' + userId + '/api');
    },
    deleteUsersApiKeyAsync(userId) {
      return axiosInstance.delete('/users/' + userId + '/api');
    },
    createUserAsync: async (user) => {
      return axiosInstance.post('/users/create', user);
    },
    updateUserAsync: async (userId, user) => {
      return axiosInstance.post('/users/' + userId, user);
    },
    getUploadsAsync: async (userId, query = {}) => {
      return axiosInstance.get('/users/' + userId + '/uploads', {params: query});
    },
  };
};

export default {
  ...requests(http)
};

module.exports.userRequests = requests;