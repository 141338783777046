<template>
  <span>
  <router-link
      v-if="id !== -1"
      :to="{name:'project', params: { id: id} }"
      active-class="active"
      v-bind:title="project.name"
      v-text="displayString">
  </router-link>
    <template v-else>
      {{ displayString }}
    </template>
  </span>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "ProjectCode",
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    showName: {
      type: Boolean,
      default: false,
    },
    showCode: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      project: null
    };
  },
  async mounted() {
    this.project = await this.getProjectByIdAsync(this.id);
  },
  watch: {
    async id() {
      this.project = await this.getProjectByIdAsync(this.id);
    }
  },
  methods: {
    ...mapActions('projects', ['getProjectByIdAsync']),
  },
  computed: {
    displayString() {
      if (this.id === -1) {
        return this.$t('project.missing');
      } else if (this.project) {
        const elements = [];
        if (this.showCode){
          elements.push(this.project.code);
        }
        if (this.showName){
          elements.push(this.project.name);
        }
        return elements.join(' ');
      } else {
        return '-';
      }
    }
  }
}
</script>

<style scoped>

</style>
