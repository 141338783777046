module.exports = {
  flash: ({success = '', generalErrors = [], fieldErrors = []}) => ({
    success,
    generalErrors,
    fieldErrors
  }),
  AuthLoginFailure: 1,
  AuthMissingActiveSession: 4,
  UserAuthenticationRequired: 2,
  RequiredValueMissing: 3,
  InternalServerError: 5,
  InvalidValue: 6,
  InvalidPermission: 7,
  ConnectionError: 8,
};

/** current understanding of the flash messages
 *
 * successMessage - a string
 * generalErrors - list
 * * errorCode - int ?
 * * message - string ?
 * fieldErrors - list
 * * param - string
 * * msg - string
 * * value - string
 * * location - string
 *
 *
 */